.bg-purple-gradient {
  background: radial-gradient(
    52.05% 158.02% at 50% 50%,
    #b568f6 0%,
    #4d1b96 100%
  );
  text-shadow: 0px 2.07958px 4.15917px rgba(0, 0, 0, 0.14),
    0px 2.07958px 2.07958px rgba(0, 0, 0, 0.12),
    0px 2.07958px 4.15917px rgba(0, 0, 0, 0.2);
}

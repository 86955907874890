label {
  top: 0%;
  transform: translateY(-50%);
  font-size: 11px;
  line-height: 11px;
  color: rgba(70, 70, 70, 1);

  /* background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 45%, rgba(255, 255, 255, 1) 45%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%); */
}

.empty input:not(:focus)+label {
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
}
input {
  border-width: 1px;
  min-height: 2.5rem;

  background: rgb(255, 255, 255);
}

input:focus {
  outline: none;
}

.btn-hide {
  line-height: 40px;
  font-size: 25px;
}
.health-bar-red {
  background: #e91416;
  border-radius: 20px;
}

.health-bar-green {
  background: #71d91a;
  border-radius: 20px;
}

.health-bar-red-top {
  background: #ff4344;
  border-radius: 48.8702px;
}

.health-bar-green-top {
  background: #86f22b;
  border-radius: 48.8702px;
}

.text-percent {
  text-shadow: 0px 2.07958px 4.15917px rgba(0, 0, 0, 0.14),
    0px 2.07958px 2.07958px rgba(0, 0, 0, 0.12),
    0px 2.07958px 4.15917px rgba(0, 0, 0, 0.2);
}

.health-bar-gray {
  background: #4c435d;
  border-radius: 20px;
}

.health-bar-gray-top {
  background: #5d536f;
  border-radius: 48.8702px;
}

.health-bar-yellow {
  background: #dab505;
  border-radius: 20px;
}

.health-bar-yellow-top {
  background: #ffe151;
  border-radius: 48.8702px;
}

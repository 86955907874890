@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sigmar+One&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.webkit-box-max-2-line {
  overflow: hidden;
  display: -webkit-box;
  /* -webkit-line-clamp: 2; */
  -webkit-box-orient: vertical;
}

/* .ingame-text-xs {
  font-size: calc(8px + 0.42vw);
  line-height: calc(8px + 0.42vw);
}

.ingame-text-sm {
  font-size: calc(8px + 0.52vw);
  line-height: calc(12px + 0.52vw);
}

.ingame-text-base {!
  font-size: calc(10px + 0.62vw);
  line-height: calc(16px + 0.62vw);
}

.ingame-text-lg {!
  font-size: calc(12px + 0.9vw);
  line-height: calc(16px + 0.9vw);
}

.ingame-text-xl {!
  font-size: calc(12px + 2.2vw);
  line-height: calc(16px + 2.2vw);
}

.ingame-text-2xl {
  font-size: calc(12px + 2.6vw);
  line-height: calc(16px + 2.6vw);
}

.ingame-text-2xl {
  font-size: 4vw;
  line-height: 4vw;
} */
